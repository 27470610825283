<template>
  <p>Redirect... in {{ timer }} seconds</p>
  <button type="button" @click="redirectWebsite">Go to the website</button>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      platform: '',
      timer: 5
    }
  },
  mounted() {
    if (window.location.href.indexOf('fr') > -1) {
      fetch('https://8a9s.adj.st?adj_t=1bnss36w_1bzx6bts&adj_campaign=Suivi+de+colis&adj_adgroup=mrcolis&adj_fallback=https%3A%2F%2Fwww.mondialrelay.fr%2F&adj_redirect_macos=https%3A%2F%2Fwww.mondialrelay.fr%2F').then(res => {});
    }
    
    const userAgent = navigator.userAgent || window.opera;

    if (/android/i.test(userAgent)) {
        // Redirect to Android-specific page
        window.location.replace('https://play.google.com/store/apps/details?id=com.mondialrelay.mobile');
      } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        // Redirect to iOS-specific page
         window.location.replace('https://apps.apple.com/fr/app/mondial-relay/id1633060599');
      } else {
        // Redirect to Desktop-specific page
        this.redirectWebsite();
      }

    setTimeout(() => {
      window.location.replace('https://www.mondialrelay.fr/suivi-de-colis/');
    }, 5000);
    
    setInterval(() => {
      if(this.timer > 0){
        this.timer--;
      }
    }, 1000);
  },
  methods: {
    redirectWebsite() {
      if (window.location.href.indexOf('fr') > -1) {
        window.location.replace('https://www.mondialrelay.fr/suivi-de-colis/');
      } else if (window.location.href.indexOf('be') > -1) {
        window.location.replace('https://www.mondialrelay.be/fr-be/suivi-de-colis/');
      } else if (window.location.href.indexOf('nl') > -1) {
        window.location.replace('https://www.mondialrelay.nl/mijn-mondial-relay-pakket-volgen/');
      } else if (window.location.href.indexOf('pt') > -1) {
        window.location.replace('https://www.inpost.es/seguimiento-del-envio/');
      } else if (window.location.href.indexOf('es') > -1) {
        window.location.replace('https://www.inpost.pt/seguimento-do-envio/');
      } else {
        window.location.replace('https://www.mondialrelay.fr/suivi-de-colis/');
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

button {
  background-color: #96154a;
  color: white;
  padding: 1em;
  cursor: pointer;
  border: 1px solid #96154a;
  border-radius: 10px;
}

button:hover {
  background-color: white;
  color: #96154a;
}
</style>
